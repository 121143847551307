import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"

const NosotrosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Politicas de privacidad"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">

                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Privacy policy</h2>
                            </div>

                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 ">
                                <p>Latam Capital Asesoria e Infraestructura, S.C. ("LCA", "We" or "Us") pays due attention to privacy issues on
                                    the Internet. The following is the Privacy Policy for this Internet site (the
                                    "Site"). The Privacy Policy set forth below details how we collect information about
                                    you on the Site, how we use it, and how you may contact us to inquire about such
                                    information. We believe it is important for you to be informed about how we handle
                                    information collected from you on the Site.</p>
                                <p>We recommend that in addition to carefully reading this Privacy Policy, please click
                                    here to read our Terms and Conditions, which explain the manner in which this Site
                                    is used.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    INFORMATION WE COLLECT
                                </h6>
                                <p><b>Personally Identifiable Information (“PII”) or personal data</b>. In order to use
                                    certain features of the Site, we may ask you to provide us with PII, such as your
                                    name, zip code, email address, telephone number, fax number, customer identification
                                    number, personal records, or other PII. In addition, if you access specific
                                    password-protected pages of this Site, (a) once you submit your password and enter
                                    the Site, the Site will recognize who you are and collect all information submitted
                                    by you, including all electronic data (including all transaction-related
                                    information), and (b) any information collected about you through this Site may be
                                    associated with other identifying information we have about you. All information you
                                    provide through the Site will be sent to and stored on servers located in the United
                                    States.</p>

                                <p>We (including our affiliates) and our business partners may also use PII to respond
                                    to your inquiry, to email you content or alerts (including updates to the Site, the
                                    Terms and Conditions, or this Privacy Policy) or to allow you to access specific
                                    account information. In addition to the foregoing, we may use any PII submitted in
                                    response to our job postings during our review process, which may include disclosure
                                    to affiliates and third parties who assist us with our review.</p>

                                <p>Importantly, we make every effort to use administrative, technical, personnel, and
                                    physical measures to protect PII in our custody from loss, theft, and unauthorized
                                    use, disclosure, or alteration. </p>

                                <p><b>Non-Personally Identifiable Information (“non-PII”) or anonymous data</b>. In
                                    general, you can use the Site without being required to disclose any PII. However,
                                    our Internet servers collect certain information that cannot be used to distinguish
                                    or trace your personal identity. For example, we collect and record the Internet
                                    Protocol address of a visitor's computer (which is the address of your computer on
                                    the Internet), the browser a visitor uses (e.g., Internet Explorer), the operating
                                    system (e.g., Windows), and the domain names you have visited prior to entering the
                                    Site and after leaving the Site. This information is compiled to measure statistics
                                    about our visitors, such as the number of visits to the Site, average time spent on
                                    the Site, and pages viewed on the Site. We use this information to monitor the use
                                    of the Site and to improve its contents. Except for certain permitted disclosures to
                                    law enforcement authorities (discussed below) and certain technical features of the
                                    Site (e.g., maintaining session status), we do not link such information with PII,
                                    it is used only on an aggregate basis.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    INFORMATION STORED ON YOUR COMPUTER
                                </h6>

                                <p>We may send text files (e.g., "cookies" or other cached files) or images to your
                                    Internet browser to store information on your computer. Such text and image files
                                    are used for technical convenience to store information on your computer. For
                                    example, we may use a session cookie to store information you have entered on a form
                                    so that you do not need to re-enter that information later. We may use information
                                    stored in such text and image files to personalize your experience on the Site and
                                    to monitor Site usage. You may be able to set your browser to notify you when you
                                    receive a cookie. Many Internet browsers allow you to block cookies.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    DISCLOSURE OF PERSONALLY IDENTIFIABLE INFORMATION
                                </h6>
                                <p><b>Disclosure to Affiliates and Partners</b>. We may share your PII with our
                                    affiliates and partners who perform services on our behalf. For example, and not
                                    limited to, our business partners may send you email alerts in the event that you
                                    have signed up to receive such information. We require our affiliates and partners
                                    to maintain the confidentiality of your PII. Any third party to whom we provide PII
                                    will be instructed to use it only in accordance with this Privacy Policy and as
                                    permitted by applicable laws and regulations. Unless previously authorized by you,
                                    or if required by applicable law, or as otherwise specified in this Privacy Policy,
                                    we will not display your PII on the Site or disclose your PII to third parties other
                                    than affiliates or partners who perform services on our behalf.</p>

                                <p><b>Disclosure as Part of a Transaction Conducted by LCA</b>. Notwithstanding any of
                                    the provisions contained in this Privacy Policy, we reserve the right to transfer
                                    PII to a third party without your authorization as part of a stock sale, merger or
                                    consolidation, or other transfer of the assets of LCA and/or an affiliate or
                                    division, either voluntarily or pursuant to applicable law, provided that such third
                                    party agrees to safeguard the confidentiality of your PII in a manner consistent
                                    with this Privacy Policy.</p>

                                <p><b>Disclosure to Law Enforcement Authorities</b>.
                                    Notwithstanding the provisions contained in this Privacy Policy, we may disclose
                                    information about you, including, without limitation, your PII to law enforcement
                                    authorities or other government officials or agencies pursuant to the USA PATRIOT
                                    Act (or other applicable statute or regulation, or in response to a subpoena, search
                                    warrant, or court order). In addition, we may disclose your PII or other information
                                    about you if we reasonably believe that such disclosure is necessary for the
                                    purposes of an official investigation or in response to a significant threat to a
                                    person's health or property. We may disclose information about you in order to
                                    comply with the Terms and Conditions of Use and Acceptable Use Policy, to address
                                    claims of violations of third-party rights, or to protect the rights of LCA. To the
                                    extent permitted by law, we will make reasonable efforts to notify you of any
                                    request to disclose your information as soon as possible after receipt of the
                                    request.</p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    CORRECTIONS, UPDATES AND REQUESTS FOR INFORMATION
                                </h6>
                                <p>Upon receipt of your written request, we will provide you with a copy of all PII we
                                    have collected from you on or through the Site. Upon written request to LCA, you may
                                    correct any inaccurate PII or request that LCA delete such PII. Such request should
                                    be sent to:<br/>
                                    <a href="mailto:info@latamcapitaladvisors.com"
                                       target="_blank">Info@Latamcapitaladvisors.com</a></p>
                                <p>LCA reserves the right to verify your identity before disclosing any such PII to you.
                                    If you request alerts, investor information, or other information by e-mail and
                                    provide us with your e-mail address, we may send you such information on a regular
                                    basis. If you do not wish to receive such e-mails, you may opt-out of receiving such
                                    e-mails by writing to:
                                    <br/><a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>

                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    THIRD-PARTY SITES
                                </h6>
                                <p>The Site may contain links to websites run by unaffiliated third parties. LCA assumes
                                    no responsibility for the content or privacy practices employed by other sites.
                                    Please read the terms and conditions of use and privacy policy of the linked
                                    site.</p>
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    NOTICE TO CALIFORNIA RESIDENTS - CALIFORNIA PRIVACY RIGHTS
                                </h6>
                                <p>If you are a California resident, you have the right to receive: (a) information
                                    showing that we have disclosed, to any third-party company within the last year,
                                    personal data pertaining to you and your family for that company's direct marketing
                                    purposes; and (b) a description of the types of personal information disclosed. To
                                    obtain such information please send a written request to:<br/>
                                    <a href="mailto:info@latamcapitaladvisors.com"
                                       target="_blank">Info@Latamcapitaladvisors.com</a></p>
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray">
                                    CONTACT US
                                </h6>
                                <p>If you have specific questions about our Privacy Policy, Terms and Conditions of Use
                                    or Acceptable Use Policy, please contact LCA's IT Security Officer by writing
                                    to:</p>
                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    11000, Mexico City, Mexico <br/>
                                    52-55-5202-6080 <br/>
                                    or to: <a href="mailto:info@latamcapitaladvisors.com"
                                              target="_blank">Info@Latamcapitaladvisors.com</a></p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
